module.exports.json = (method, path, data, token, baseURL = window.APIURL) => {

  let body = null;
  if (data) {
    body = {};
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        body[prop] = data[prop];
      }
    }
    body = JSON.stringify(data);
  }

  const headers = { "Content-Type": "application/json" };
  if (token) {
    headers.Authorization = "Bearer " + token
  }

  const url = baseURL + path

  return fetch(url, { method, headers, body: body })
    .then(res => res.json())
    .then(
      result => {
        if (result.status || result.code === 200 || result.status == "success") return { status: true, result };
        return { status: false, result };
      },
      error => {
        return { status: false, error };
      }
    );
};

module.exports.multipart = (path, data, token) => {

  const formData = new FormData();
  for (const prop in data) {
    if (data[prop]) {
      formData.append(prop, data[prop])
    }
  }

  const headers = {};
  if(token) {
      headers.Authorization = "Bearer " + token
  }

  const url = window.APIURL + path

  return fetch(url, {
    method: 'post',
    body: formData,
    headers: headers
  })
    .then(res => res.json())
    .then(
      result => {
        if (result.status === true) return { status: true, result };
        return { status: false, result };
      },
      error => {
        return { status: false, error };
      }
    );
};
