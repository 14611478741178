import React, { Component, lazy, Suspense } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import apiHelper from '../helper/api.helper';
import CMS from './CMS';
import loaderImage from '../images/loader.svg';

const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
const Contact = lazy(() => import('./Contact'));
const Documentation = lazy(() => import('./Documentation'));
const APIPricing = lazy(() => import('./APIPricing'));
const APIProduct = lazy(() => import('./APIProduct'));
const Pricing = lazy(() => import('./Pricing'));
const Product = lazy(() => import('./Product'));
const Notfound = lazy(() => import('../components/Notfound'));
const PrivacyPrivacy = lazy(() => import('./Privacy-Privacy'));
const EsignPandaIsUsed = lazy(() => import('../components/EsignPandaSignerPages/isUsed'));
const _401 = lazy(() => import('../components/401'));
const EsignPandaIsSuccess = lazy(() => import('../components/EsignPandaSignerPages/isSuccess')); 

class Body extends Component {
  state = {
    pages: [],
    call : false
  }

  async componentDidMount() {
    
    const {error, status, result} = await apiHelper.json('post', 'cms/get', {pageNumber:1}, null);
    
    if(!status) return;
    
    this.setState({pages: result.data ? result.data : []});
    this.props.onPagesReceived(result.data ? result.data : [])
    this.setState({call : true })
  }

  render() {
    return (
      <HashRouter>
        <Suspense fallback={<div className="inlineLoaderGif"> <img src={loaderImage} alt="broken" /></div>}>
          <Switch>
            <Route exact path="/" name="Home" component={Home} />
            <Route exact path="/about" name="About" component={About} />
            <Route exact path="/contact" name="Contact" component={Contact} />
            <Route exact path="/documentation" name="Documentation" component={Documentation} />
            <Route exact path="/api-pricing" name="Pricing" component={ APIPricing} />
            <Route exact path="/api-product" name="Product" component={APIProduct} />
            <Route exact path="/pricing" name="Pricing" component={Pricing} />
            <Route exact path="/Privacy-Privacy" name="Pricing" component={PrivacyPrivacy} />
            <Route exact path="/Product" name="Product" component={Product} />
            <Route exact path="/isSuccess" name="api eSignPanda" component={EsignPandaIsSuccess} />
            <Route exact path="/isUsed" name="api eSignPanda" component={EsignPandaIsUsed}/>
            {this.state.pages.map(page =>
                <Route exact path={"/" + page.slug} name="asd" render={(props) => <CMS {...props} page={page} />} />
            )}
            <Route exact path="/401" name="401" component={_401} />
            <Route exact path="*" name="404" component={Notfound} />
          </Switch>
          </Suspense>
      </HashRouter>
    );
  }
}

export default Body;
