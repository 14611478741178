import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu,Dropdown,  DropdownToggle, Nav} from 'reactstrap';
import { AppHeaderDropdown } from '@coreui/react';
import loaderImage from './../images/profile-img.jpg';
import apiHelper from './../helper/api.helper';
import { decrpt} from '../comman/Crypto';
import $ from 'jquery';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      logout: false,
      userData: [],
      profileimage: "",
      firstname: '',
      toggleNavMobile: false,
      dropdownOpen: false

    }
    this.menuToggle = this.menuToggle.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  componentWillMount() {
   


    var check = localStorage.getItem('check');
    if (check == "false") {
      localStorage.removeItem('LoginSession');
      localStorage.removeItem('loginTime');
      localStorage.removeItem('check');
      localStorage.removeItem('accessToken');
    }
    var userData = decrpt('LoginSession');
    var userDataArray = userData;
    if (userDataArray != null) {
      if(!userDataArray.user.profileimage)
        userDataArray.user.profileimage = loaderImage;
      else if (!userDataArray.user.profileimage.includes('http'))
        userDataArray.user.profileimage = window.APPURL + 'images/uploads/users/' + userDataArray.user.profileimage;
      var user = userDataArray.user;
    }
    if (user) {
      if (user.role == "user") {
        if (user.initialCheck) {

        } else {
          localStorage.removeItem('LoginSession');
          localStorage.removeItem('loginTime');
          localStorage.removeItem('Check');
          localStorage.removeItem('accessToken');
          this.setState({ logout: true });
        }
      }
    }
    if (userDataArray != null) {
      if (userDataArray.user.profileimage != undefined && userDataArray.user.profileimage != '') {
        this.setState({
          profileimage: userDataArray.user.profileimage,
          firstname: userDataArray.user.firstname
        })
      }
    }
    var token = localStorage.getItem('accessToken');
    if (token) {
      var loginTime = parseInt(localStorage.getItem('loginTime')) / 1000;
      var timeNow = (new Date().getTime()) / 1000;
      var timeDifference = timeNow - loginTime;
      var timeDifferenceInMinutes = timeDifference / 60;
      if (timeDifferenceInMinutes > window.loginExpiresAfter) {
        this.setState({ logout: true });
      }
    }
    else {
      this.setState({ logout: true });
    }
  }
  async componentDidMount(){
    $(".menu-has-children").click(function(){
      if($(this).hasClass(('active')))
      {
        $(".menu-has-children").removeClass('active');
      }
      else
      {
        $(".menu-has-children").removeClass('active');
        $(this).addClass('active');
      }
      
    });

      
      const token = localStorage.getItem("accessToken");
      const user = decrpt("LoginSession");

     // During Shafiq Header And Footer Working we find this Api have not functionl working. 

    //  if(!user || !user.user) return window.location = "#/";
    //   //if (user.user.role == "Super Admin" || user.user.role == "admin") return;
    //   const { error, result } = await apiHelper.json("post", "api/package/stats", null, token);

    //   if (error) {
    //     return;
    //   }
      
    //   if(result.status == 'error' || result.message == 'TokenMissing'){
    //     localStorage.clear()
    //     return window.location = "#/";

    //   }
        
    //   this.setState({ payment: result.data[0] });
  }
  menuToggle() {
    this.setState({
      toggleNavMobile: !this.state.toggleNavMobile
    })
  }
  //nav toggle code
  toggleSideBar() {
    var mainNav = document.getElementsByClassName('metismenu');
    mainNav[0].classList.toggle('collapsed');
  }
  //nav toggle code end

  onLogOut = (e) => {
    localStorage.clear();
    localStorage.removeItem('LoginSession');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('loginTime');
    window.location = `${window.eMarkitingUrl}#/logout`;
    // this.setState({ logout: true });
  }
  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>
  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {

    var userData = decrpt('LoginSession');
    var userDataArray =  userData;

    return (
      <header id="header" className={window.location.hash === '#/' ? "" : "landingpageheader" }>
        <div className="container">
          <div className="header-wrap">
            <div id="logo" className="site-logo logo-size">
              <Link to="/"><img src="img/logo-01.svg" className="img-responsive" alt="broken" /></Link>
            </div>
            <nav id="nav-menu-container" className="main-head-navigation">
              <span className="menu-icon d-sm-block d-lg-none" onClick={this.menuToggle}><i className="fa fa-bars" aria-hidden="true"></i></span>
              <ul className={(this.state.toggleNavMobile) ? 'show nav-menu' : 'nav-menu head-nav'}>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li className="menu-has-children"><Link to="#">Products<i className="fa fa-angle-down"></i></Link>
                  <ul>
                    <li><Link to="/product">eSign</Link></li>
                    <li><Link to="/api-product">eSign API</Link></li>
                  </ul>
                </li>
                <li className="menu-has-children"><Link to="#">Pricing<i className="fa fa-angle-down"></i></Link>
                  <ul>
                    <li><Link to="/pricing">eSign Pricing</Link></li>
                    <li><Link to="/api-pricing">eSign API Pricing</Link></li>
                  </ul>
                </li>
                <li className="menu-has-children"><Link to="#">API<i className="fa fa-angle-down"></i></Link>
                  <ul>
                    <li><Link to="/documentation">Documentation</Link></li>
                  </ul>
                </li>
                <li><Link to="/contact">Contact us</Link></li>
              </ul>
          </nav>
          <ul className="head-nav head-nav-btn">
                <li style={{ display: this.state.logout == true ? "block" : "none" }}><Link to="/register" className="ouline-btn">Signup</Link></li>
                <li style={{ display: this.state.logout == true ? "block" : "none" }}><Link to="/login" className="btn-blue head-btn">Login</Link></li>
                <Nav className="d-md-down-none mobile" navbar> 
                <AppHeaderDropdown className="navbar-right-area" style={{ display: this.state.logout == false ? "block" : "none" }} direction="down">
                  <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle nav>
                      <div className="img_wrap">
                        <img id="userProfileThumbnail" src={this.state.profileimage} className="img-avatar" alt={this.state.firstname} />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>
                        <Link to="/dashboard"><DropdownItem><i className="ti-dashboard"></i> Dashboard</DropdownItem></Link>
                        <Link to="/profile"><DropdownItem><i className="ti-user"></i> Profile</DropdownItem></Link>
                        <Link to=""><DropdownItem onClick={this.onLogOut.bind(this)}><i className="ti-lock"></i> Logout</DropdownItem></Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </AppHeaderDropdown>
            </Nav>
              </ul>
          </div>
          {/* #nav-menu-container */}
        </div>
      </header>
    );

  }
}

export default Header;

