import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Notifications, { notify } from 'react-notify-toast';
import $ from 'jquery';
import MTD from './helperStatic/metaTitleDescription';

class Footer extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors:''
    }
  }

  componentDidMount() {
    // const recaptchaScript = '<script src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit" async defer></script>';
    // $("body").append(recaptchaScript);

    var scripts = '<script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=1f2fb931-8d04-4614-a2d4-0298309bdfba"> </script>';  
    //let $ = window.jQuery;
    $("body").append(scripts);
    var btn = $('#back-top-button');
      
        $(window).scroll(function() {
          if ($(window).scrollTop() > 300) {
            btn.addClass('show');
          } else {
            btn.removeClass('show');
          }
        });

        btn.on('click', function(e) {
          e.preventDefault();
          $('html, body').animate({scrollTop:0}, '300');
        });
  }
  
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit = (e) => {
    const {email , errors  } = this.state;
    e.preventDefault();
     
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email === '')
    {
      this.setState({ errors: 'Email is Required' });
      return;
    }
    else if (!email.match(regexp))
    {
      this.setState({ errors: 'Email is Invalid' });
      return;
    }
    fetch(window.APIURL + 'api/newslatter/create', {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'authorization': 'bearer '
      },
      body: JSON.stringify(
        { 
          'email': email
        })
    })
      .then(response => response.json())
      .then(data => {
        
        if (data.status === "success") {
          
          notify.show(data.message, data.status, 3500);
          this.setState({
            email:"" , errors :""
          })
        }
        if(data.driver == true){
           notify.show("Already Subscribed!", "warning", 3500);
        }
      }, (error) => {
        console.log("");
      });
  }
  goTo(){
    window.open("https://www.arhamsoft.com/", "_blank")
  }
  render() {
    return (
      <footer className="footer">
       <Notifications />
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 ">
              <div className="ft-logo"><img src="img/logo-02.svg" alt="esignpanda logo-01" /></div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 ">
              <div className="social"><Link to="#"><span className="fa fa-facebook"></span></Link> <Link to="#"> <span className="fa fa-twitter"></span> </Link> <Link to="#"><span className="fa fa-google-plus"></span></Link></div>
            </div>
          </div>
          <div className="row spaceT40">
            {/* footer-about */}
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12 ">
              <div className="footer-widget">
                <div className="footer-title">Company</div>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/about">About Us</Link></li>
                  <li><Link to="/contact">Contact Us</Link></li>
                </ul>
              </div>
            </div>
            {/* /.footer-about */}
            {/* footer-links */}
            <div className="col-lg-6 col-md-8 col-sm-8 col-12 ">
              <div className="footer-widget dynamic-link-wrap">
                <div className="footer-title">Quick Links</div>
                <div className="footer-title">Useful Links</div>
                <ul>
                  <li><Link to="/product">eSign Product</Link></li>
                  <li><Link to="/api-product">eSign API Product</Link></li>
                  <li><Link to="/pricing">eSign Pricing</Link></li>
                  <li><Link to="/documentation">Documentaion</Link></li>
                  <li><Link to="/Privacy-Privacy">Privacy Policy</Link></li>
                  {this.props.pages.map(page => <li><Link to={'/' + page.slug}>{page.title}</Link></li>)}
                </ul>
              </div>
            </div>
            {/* /.footer-links */}
            {/* footer-links */}
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 ">
              <div className="footer-widget ">
                <h3 className="footer-title">Subscribe Newsletter</h3>
                <form onSubmit={this.onSubmit.bind(this)}>
                  <div className="newsletter-form">
                    <input className="form-control" name="email" value={this.state.email} onChange={this.onChange} placeholder="Your Email address" type="text" />
                    <div className="newsletter-btn">
                    <button className="btn-sm btn-front" type="submit">Go</button>
                    </div>
                  </div>
                </form>
                <div></div>
                {Object.keys(this.state.errors).length > 0 && this.state.errors ?
                        <div className="alert alert-danger">
                          {this.state.errors}
                        </div> : ''}
              </div>
            </div>
            {/* /.footer-links */}
            {/* tiny-footer */}
          </div>
          <div className="row ">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center ">
              <div className="tiny-footer">
                <p>Copyright © All Rights Reserved 2019 | Design &amp; Development by <Link to="#" onClick={this.goTo} className="copyrightlink">Arhamsoft</Link></p>
              </div>
            </div>
            {/* /. tiny-footer */}
          </div>
        </div>
        <a id="back-top-button">
          <i className="fa fa-angle-up"></i>
        </a>
      </footer>
    );
  }
}

export default Footer;
