import React, { Component, lazy, Suspense } from 'react';

// import EsignPandaIfram from './components/EsignPandaIfram';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import './styles/bootstrap/css/bootstrap.css';
import './styles/font-awesome/css/font-awesome.min.css';
import './styles/esignpanda.css';
import $ from 'jquery';
import apiHelper from './helper/api.helper';
import loaderImage from './images/loader.svg';
import Login from './components/Login';
import FrontMain from './staticPages/FrontMain';
import DropBoxPickerApp from './components/DropBox/filePicker';



const PackageLinkExpire = lazy(() => import('./components/verify/packageLinkExpire'));
const EmailVerifyExpire = lazy(() => import('./components/verify/emailVerifyExpire'));
const Register = lazy(() => import('./components/userSection/Register'));
const Main = lazy(() => import('./components/Main'));
const Upload = lazy(() => import('./components/Upload'));
const EsignPanda = lazy(() => import('./components/EsignPanda'));
const EsignPandaPreview = lazy(() => import('./components/EsignPandaView'));
const EsignPandaOpenLink = lazy(() => import('./components/EsignPandaOpenLink'));
const NewPassword = lazy(() => import('./components/newPassword/forgotPassword'));
const EmailVerify = lazy(() => import('./components/verify/emailVerify'));
const EsignPandaMultipleSigner = lazy(() => import('./components/EsignPandaMultipleSigner'));
const GetEmail = lazy(() => import('./components/newPassword/forgotPasswordGetEmail'));
const InviteUserCallback = lazy(() => import('./components/adminUserSection/inviteUserCallback'));
const socialLogin = lazy(() => import('./components/SocialLogin'));
const OTP = lazy(() => import('./components/verify/OTP'));
const editorWrapper = lazy(() => import('./components/editor/editorWrapper'));
const EditorSuccess = lazy(() => import('./components/editor/success'));
const EditorWarning = lazy(() => import('./components/editor/warning'));
const GoogleIncommingFile = lazy(() => import('./components/GoogleDrive/googleIncomingFile'));
const Logout = lazy(() => import('./components/logout'));
const ChromAppDriveApp = lazy(() => import('./components/GoogleDrive/DriveAccessForChromeApp'));

const GmailSuccess = lazy(() => import('./components/editor/GmailSuccess'));
const ComingSoon = lazy(() => import('./components/editor/comingSoon'));
const ResetPassword = lazy(() => import('./components/userSection/resetPassword'));
// const DropBoxPickerApp = lazy(() => import('./components/DropBox/filePicker'));


class App extends Component {
  state = {
    pages: []
  }

  componentWillMount(){
    $('#overlay').fadeOut();
    }

  async componentDidMount() {
    const {error, status, result} = await apiHelper.json('post', 'cms/get', {pageNumber:1}, null);
    if(!status) return;
    this.setState({pages: result.data ? result.data : []});
  }

  render() {
    return (
      <BrowserRouter>
      <HashRouter >
        <Suspense fallback={<div className="inlineLoaderGif"> <img src={loaderImage} alt="broken" /></div>}>
        <Switch>
          <Route exact path="/" name="Login" component={FrontMain} />
          <Route exact path="/login" name="Login" component={Login} /> 
          <Route exact path="/account/logIn" name="Login" component={Login} />
          <Route exact path="/resetPassword" name="ResetPassword" component={ResetPassword} />

          <Route exact path="/home/editorLoader" name="editorLoader" component={editorWrapper} />
          
          <Route exact path="/ChromAppDriveApp" name="ChromAppDriveApp" component={ChromAppDriveApp} />
          <Route exact path="/home/editorLoader/success" name="editorLoader" component={EditorSuccess} />
          <Route exact path="/home/editorLoader/warning" name="editorLoader" component={EditorWarning} />


          <Route exact path="/home/GmailSuccess" name="editorLoader" component={GmailSuccess} />
          <Route exact path="/home/ComingSoon" name="editorLoader" component={ComingSoon} />
          

          {/* GmailSuccess */}
          <Route exact path="/home/dropBox" name="editorLoader" component={DropBoxPickerApp} />
          {/* DropBoxPickerApp */}
          
          <Route exact path="/home/googleIncomingFile" name="editorLoader" component={GoogleIncommingFile} />
          <Route exact path="/logout" name="Login" component={Logout} />
          <Route exact path="/dashboard" name="Dashboard" component={Main} />
          <Route exact path="/create-template" name="Create Template" component={Main} />
          <Route exact path="/create-template-link" name="Create Template Link" component={Main} />
          <Route exact path="/create-template/edit/:id" name="Create Template" component={Main} />
          <Route exact path="/template-link/edit/:id" name="Create Template" component={Main} />
          <Route exact path="/use-templates" name="Use Templates" component={Main} />
          <Route exact path="/use-templates-link" name="Use Templates Link" component={Main} />
          <Route exact path="/share-templates" name="Use Templates" component={Main} />
          <Route exact path="/documents-signed" name="Documents Signed" component={Main} />
          <Route exact path="/documents-canceled" name="Documents Canceled" component={Main} />
          <Route exact path="/documents-unsigned" name="Documents Unsigned" component={Main} />
          <Route exact path="/documents-inprogress" name="Documents Inprogress" component={Main} />
          <Route exact path="/documents-all" name="Documents All" component={Main} />
          <Route exact path="/documents-Delete" name="Documents Delete" component={Main} />




          <Route exact path="/create-app" name="Create App" component={Main} />
          <Route exact path="/use-app" name="Use App" component={Main} />
          <Route exact path="/contact-inquiries" name="Use App" component={Main} />
          <Route exact path="/profile" name="Profile" component={Main} />
          <Route exact path="/apps/edit/:id" name="Edit App" component={Main} />
          <Route exact path="/register" name="Register" component={Register} />
          <Route exact path="/upload" name="Login Page" component={Upload} />
          <Route exact path="/call-log/:id" name="Edit App" component={Main} />
          <Route exact path="/API/eSignPanda" name="api eSignPanda" component={EsignPanda} />
          <Route exact path="/API/eSignPanda/preview" name="api eSignPanda" component={EsignPandaPreview} />
        
          <Route exact path="/API/eSignPanda/sign" name="api eSignPanda" render={()=> <EsignPandaMultipleSigner />}  />
          <Route exact path="/API/eSignPandaOpenLink/sign" name="api 123eSignPanda" component={EsignPandaOpenLink} />

          <Route exact path="/isSuccess" name="api eSignPanda" component={FrontMain} />
          <Route exact path="/isUsed" name="api eSignPanda" component={FrontMain}/>


          <Route exact path="/API/eSignPanda2" name="api eSignPanda preview" component={EsignPandaPreview} />
          <Route exact path="/packages-all" name="Packages All" component={Main} />
          <Route exact path="/packages-create" name="Packages Create" component={Main} />
          <Route exact path="/packages-create/edit/:id" name="Packages Create" component={Main} />
          <Route exact path="/packages-api-all" name="user package" component={Main} />
          <Route exact path="/packages-api-create/edit/:id" name="user package" component={Main} />
          <Route exact path="/packages-api-create" name="apiPackage Create" component={Main}/>
          <Route exact path="/packages-link-expired" name="linkedExpired" component={PackageLinkExpire}/>



          <Route exact path="/just-me" name="just-me" component={Main} />
          <Route exact path="/me-Other" name="meAndOther" component={Main} />
          <Route exact path="/just-Other" name="justOther" component={Main} />


          <Route exact path="/payment/:id/:type" name="Payment" component={Main} />
          <Route exact path="/apiPayment/:id/:type" name="apiPayment" component={Main} />
          <Route exact path="/apiPaymentHistory" name="apiPaymentHistory" component={Main} />

          <Route exact path="/paymentSuccess/:id" name="PaymentSuccess" component={Main} />
          <Route exact path="/paymentSucceded" name="PaypalPaymentSuccess" component={Main} />
          <Route exact path="/paymentCancel" name="PaymentSuccess" component={Main} />
          <Route exact path="/paymentSuccess" name="PaymentSuccess" component={Main} />
          <Route exact path="/payment-error" name="PaypalPaymentSuccess" component={Main} />
          <Route exact path="/package" name="user package" component={Main} />
          <Route exact path="/packageStats" name="user packageStats" component={Main} />
          <Route exact path="/apiPackageStats" name="user packageStats" component={Main} />
          <Route exact path="/custom-packages" name="custom packages" component={Main} />
          <Route exact path="/all-features" name="all features" component={Main} />
          <Route exact path="/bulk-share" name="justOther" component={Main} />


          <Route exact path="/userAll" name="user all" component={Main} />
          <Route exact path="/user-free" name="users Free" component={Main}/>
          <Route exact path="/user-invite" name="user invite" component={Main} />
          <Route exact path="/users/acceptInvitation/:token" name="user invite" component={InviteUserCallback} />
          <Route exact path="/add-roles" name="add-roles" component={Main}/>
          <Route exact path="/edit-roles/:id" name="edit-roles" component={Main}/>


          <Route exact path="/activity-log/:id" name="activityLog" component={Main} />
          <Route exact path="/access-log" name="accessLog" component={Main} />

          <Route exact path="/settings" name="settings" component={Main} />

          <Route exact path="/userCreate" name="user create" component={Main} />
          <Route exact path="/userEdit/:id" name="user edit" component={Main} />

          <Route exact path="/emailTemplate" name="email tamplate" component={Main} />
          <Route exact path="/emailTemplate/edit/:id" name="email Edit" component={Main} />
          <Route exact path="/emailTemplate/create" name="email Edit" component={Main} />


          <Route exact path="/add-page" name="add page" component={Main} />
          <Route exact path="/edit-page/:id" name="add page" component={Main} />
          <Route exact path="/view-pages" name="add page" component={Main} />
          <Route exact path="/subscribe" name="user edit" component={Main} />
          <Route exact path="/newPassword/:email/:token" name="new password" component={NewPassword} />
          <Route exact path="/verifyEmail" name="new password" component={EmailVerify} />
          <Route exact path="/verifyEmail-expire" name="emailVerifyExpire" component={EmailVerifyExpire} />
          <Route exact path="/two-step/:token" name="otp" component={OTP} />
          <Route exact path="/forgotPassword" name="forgot password get Email" component={GetEmail} />
          <Route exact path="/social/auth/:token" name="social login" component={socialLogin} />

            <Route exact path="/api-pricing" name="Pricing" component={FrontMain} />
            <Route exact path="/api-product" name="Product" component={FrontMain} />

          <Route exact path="/401" name="401" component={FrontMain} />

          {this.state.pages.map(page => {
              return (
                <Route exact path={`/${page.slug}`} name='asda' component={FrontMain} />
              )
          })}

          <Route exact path="*" name="FrontMain Page" component={FrontMain} />
        </Switch>
        </Suspense>
      </HashRouter>
      </BrowserRouter>
    );
  }
}

export default App;
