import React from 'react';
import $ from 'jquery';


const createMarkup = (description) => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
    return { __html: description };
}

const setMetaTags = (page) => {
    //$('title').text(page.metaTitle);
    
    $('title').text(page.metaTitle);
    $('meta[name=keywords]').attr("content", page.keywords);
    $('meta[name=description]').attr("content", page.metaDescription);
}

export default (props) => {
    setMetaTags(props.page);
    return (
      <div>
          <section className="story-section space-TB-50 mt-75">
              <div className="container" dangerouslySetInnerHTML={createMarkup(props.page.description)}></div>
          </section>
      </div>
    );
}

