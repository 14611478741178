import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import DropboxChooser from './DropBoxChild';
import apiHelper from '../../helper/api.helper';
import loaderImage from '../../images/loader.svg';
const APP_KEY = 'f7btcdecjyydhr5';

export default class CreateTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }
    async GETFileDetail(data){
        var th = this;
        // this.setState({loaderActive: true});
        th.props.setUpoadValues();
        const {error, result} = await apiHelper.json("post", "dropbox/get/file", data, null);
        // this.setState({loaderActive: false})
        if(result){
            console.log(result);
            if (result.status == "warning") {
                th.setState({
                    showTemplateErrors: true,
                    uploading: false,
                    errorMessage: result.message,
                    uploadingFileMessage: '',
                    uploadingFileMessage: ''
                })
                return
            }
            if (result.data) {
                th.props.setValues(result);
            }
        }else{
            th.props.setErrorValues();
        }
    }

    render() {
        var that = this;
        return (
            <div>
                <DropboxChooser appKey={APP_KEY}
                    success={files => that.GETFileDetail(files)}
                    cancel={() => console.log('closed')}
                    multiselect={false} >
                    <span> <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi_gycCEIMzS3EORifGjP85fvl7YfBkTURt4O6pvwmtleaCubG&s"  style={{"width":"30px", "height": "30px"} } /></span>
                    <div className="dropbox"></div>
                </DropboxChooser>
                {
                    this.state.loaderActive ? <div className="inlineLoaderGif">
                        <img src={loaderImage} alt="broken" />
                    </div> : ''
                }
            </div>
        );
    }
}

