import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import logo from '../staticPages/img/logo-01.svg';
import GdriveImg from '../staticPages/img/webGmailIcon.png';
import loaderImage from './../images/loader.svg';
import { Form, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import { Stripe } from './stripePaymentForm';
import apihelper from '../helper/api.helper';
import focus from '../helper/focusHelper';
import {encrpy} from '../comman/Crypto'
const { BroadcastChannel } = require('broadcast-channel');
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: '',
      redirect: false,
      loaderActive: false,
      package: {},
      packageFree: {},
      isModalOpen: false,
      redirectUrl: '',
      redirectGmail:'',
      redirectTemplate:''
    }
  }

  async componentWillMount() {
    var th = this;
    const id = "5ca753d12b3b7652a6ceeec5";
    const urlParams = new URLSearchParams(window.location.hash);
    let searchData = new URLSearchParams(this.props.location.search);
    const clientID = urlParams.get('from_logout');
    var view = urlParams.get('view');
    var redirectUrlGmail = urlParams.get('on_login_redirect_url')
    var tokenGmail = urlParams.get('token')
    const redirectTemplate = searchData.get("redirectTemplate");
    if(redirectTemplate){
      th.setState({redirectTemplate :redirectTemplate })
    }
    if(redirectUrlGmail){
      th.setState({ redirectGmail : redirectUrlGmail +"?token="+tokenGmail })
      localStorage.clear();
      localStorage.setItem('redirectUrlGmail', redirectUrlGmail +"?token="+tokenGmail);
    }
    this.getPackage(id);
    var token = localStorage.getItem('accessToken');
    if (token) {
      var loginTime = parseInt(localStorage.getItem('loginTime')) / 1000;
      var timeNow = (new Date().getTime()) / 1000;
      var timeDifference = timeNow - loginTime;
      var timeDifferenceInMinutes = timeDifference / 60;
      if (timeDifferenceInMinutes < window.loginExpiresAfter) {
        if(redirectUrlGmail){
           window.location.href = redirectUrlGmail+"?token="+tokenGmail;
        }else if(redirectTemplate){
          window.location.href = window.APPURL+"#/home/googleIncomingFile?fileId="+redirectTemplate;
        }
        else{
          this.setState({ redirect: true });
        }
      }
      else {
        this.setState({ redirect: false });
      }
    }
    else {
      this.setState({ redirect: false });
    }
  
    let search = new URLSearchParams(this.props.location.search);
    let socialAccessToken = search.get("t");
    const redirectUrl = search.get("redirect_url");
    if (redirectUrl) {
      
      try {
        const arr = redirectUrl.split('/');
        const packageId = arr[arr.length - 2];
        const packageType = search.get('package');
        if (!packageId.match(/^[0-9a-fA-F]{24}$/))
          throw new Error("error")

        const type = arr[arr.length - 1];
        this.setState({ redirectUrl, packageId, type, packageType });
      } catch (err) {
        this.setState({ packageId: '' });
      }
    }
    let error = search.get('error');
    if (error) {
      return this.setState({ errors: 'Email already exist' })
    }

    if (socialAccessToken) {
      const data = {
        socialToken: socialAccessToken
      }

      this.setState({ loaderActive: true });
      const { error, result } = await apihelper.json('post', 'auth/social/verify', data, null);
      this.setState({ loaderActive: false });
      if (error) {
        return this.setState({ errors: 'Something went wrong' });
      }

      if (!result) {
        return this.setState({ errors: 'Something went wrong' });
      }

      if (result.code != 200) {
        return this.setState({ errors: result.message });
      }
      this.getPackage(id);
      encrpy(result, 'LoginSession')
      localStorage.setItem('accessToken', result.token.accessToken);
      localStorage.setItem('loginTime', new Date().getTime());
      localStorage.setItem("check", result.user.initialCheck);
      if (result.user.initialCheck !== true) {
        this.freeTrail();
      } else {
        return this.setState({ redirect: true });
      }

    }
  }


  getPackage(id) {
    if (typeof id !== "undefined") {
      var token = localStorage.getItem('accessToken');
      fetch(window.APIURL + 'api/package/editData', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }, body: JSON.stringify({
          "_id": id
        })
      }).then(res => res.json()).then(
        (result) => {

          if (result.status === "success") {
            this.setState({
              package: result.data,
              Action: 'Update'
            });
          } else {
            this.setState({ logout: true });
          }
        },
        (error) => {
          console.log("");
        });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }


  onSubmit = (e) => {
    var that = this;
    const { email, password } = this.state;
    e.preventDefault();
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '') {
      this.setState({ errors: 'Email is Required' });
      focus('email');
      return;
    }
    else if (!email.match(regexp)) {
      this.setState({ errors: 'Email is Invalid' });
      focus('email');
      return;
    }
    else if (password === '') {
      focus('password');
      this.setState({ errors: 'Password is Required' });
      return;
    }
    else {
      this.setState({ loaderActive: true });
      this.setState({ errors: '' });
      fetch(window.APIURL + 'auth/login', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "email": email,
          "password": password
        })
      }).then((res) => {
        if (res.status === 200) {
          var ch = new BroadcastChannel('refresh');
          ch.postMessage('some data');
          return res.json();
        }
        else {
          this.setState({ errors: 'Incorrect email or password' });
          return 'InvalidCredentialsProvided';
        }
      }).then(
        (result) => {
          this.setState({ loaderActive: false });
          if(result.Url){
            return window.location = result.Url
          }
          if (result.twoStepVerification)
            return window.location = `#/two-step/${result.token}`
          if (this.state.errors.length < 1) {
            encrpy(result , 'LoginSession')
            localStorage.setItem('accessToken', result.token.accessToken);
            localStorage.setItem('loginTime', new Date().getTime());
            localStorage.setItem("check", result.user.initialCheck);
            if (result.user.initialCheck !== true) {
              this.freeTrail();
            }else if (that.state.redirectGmail != ""){
              window.location.href = window.APPURL+'#/home/editorLoader/warning';
            }else if (that.state.redirectTemplate != ""){
              window.location.href = window.APPURL+"#/home/googleIncomingFile?fileId="+that.state.redirectTemplate;
            }else {
              this.setState({ redirect: true });
              if (this.state.redirectUrl) {
                return window.location = `#${this.state.redirectUrl}`;
              }
            }
          }
        });
    }
  }
  freeTrail() {

    var token = localStorage.getItem('accessToken');
    fetch(window.APIURL + 'api/assign/package', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }, body: JSON.stringify({
        "packageId": "5ca753c42b3b7652a6ceeec4"
      })
    }).then(res => res.json()).then(
      (result) => {

        if (result.status === "success") {
          localStorage.setItem("check", result.user.initialCheck);
          this.setState({
            isModalOpen: false,
            redirect: true
          })
        } else {
          this.setState({ logout: true });
        }
      },
      (error) => {
        console.log("error");
      });
  }
  closeModel() {
    this.setState({
      isModalOpen: false,
      redirect: true
    })
  }

  onSocialLogin = (path) => {
    window.location = window.APIURL + path;
  }

  render() {
    const { redirect } = this.state;
    debugger
    if (redirect) {
      return <Redirect to='/dashboard' />;
    }
    return (
      <div className="container-fluid height100 backgroundcolor d-flex align-content-center flex-wrap login-form">
        <div className="row justify-content-center" id="login">
          <div className="col-xs-12 col-md-10 col-lg-2 text-center" id="left">
            <Link to="/"><img src={logo} alt="eSignPanda Logo" /></Link>
          </div>
          <div className="col-xs-12 col-md-10 col-lg-4" id="right">
            <h1 className="text-center headingsignin">Sign In</h1>
            <Form onSubmit={this.onSubmit.bind(this)} className="login-form-field">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div id="username" className="form-input">
                    <Input type="text" placeholder="Email" ref={input => this.email = input} name="email" value={this.state.email} onChange={this.onChange} autoComplete="username" />
                  </div>
                </div>
                <div className="col-md-12 col-xs-12">
                  <div id="password" className="form-input">
                    <Input type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.onChange} autoComplete="current-password" />
                  </div>
                </div>
              </div>
              {Object.keys(this.state.errors).length > 0 && this.state.errors ?
                <div className="alert alert-danger">
                  {this.state.errors}
                </div> : ''}
              {
                this.state.loaderActive ? <div className="inlineLoaderGif">
                  <img src={loaderImage} alt="broken" />
                </div> : ''
              }
              <div className="login-acction">
                <a href="/#/forgotPassword" className="text-left">Forgot Password?</a>
                <a href={this.state.packageId ? `/#/register/?id=${this.state.packageId}&name=custome&type=${this.state.type}&planType=5&isChange=true&package=${this.state.packageType}` : `/#/register`} className="text-right">Create an Account</a>
              </div>
              <div className="social-links">
                <Button disabled={this.state.loaderActive ? true : false} type="submit" className="loginbutton">Login</Button>
                <ul>
                  <li>
                    <a onClick={() => this.onSocialLogin('auth/facebook')} className="facebook">
                      <i className="fa fa-facebook"></i>
                      <span>Sign in with Facebook</span>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.onSocialLogin('auth/google')} className="envelope">  <img src={GdriveImg} alt="eSignPanda Logo" />
                      {/* <i className="fa fa-google"></i> */}
                    
                      {/* GdriveImg */}
                    </a>
                  </li>
                </ul>
              </div>
            </Form>
          </div>
        </div>
        <Modal className='modal-lg package-payment-detail-modal' isOpen={this.state.isModalOpen}>
          <ModalHeader>
            <div>
            </div>
          </ModalHeader>
          <ModalBody className="esignModal-body">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-5">
                  <h3 className='modal-title'>Package Detail</h3>
                  <div className='package-detail-info-inner'>
                    <div className="form-group"><i className="fa fa-check-square-o"></i><label>Package Name: </label> <span>{this.state.package.name}</span></div>
                    <div className="form-group"><i className="fa fa-check-square-o"></i><label>Package Price: </label> <span>${this.state.package.price}</span></div>
                    <div className="form-group"><i className="fa fa-check-square-o"></i><label>Total Document: </label> <span>{this.state.package.totalDocument === -1 ? "Unlimited" : this.state.package.totalDocument}</span></div>
                    <div className="form-group"><i className="fa fa-check-square-o"></i><label>Total Template: </label> <span>{this.state.package.totalTemplate}</span></div>
                    <div className="form-group"><i className="fa fa-check-square-o"></i><label>Plan Type: </label> <span>{this.state.package.planType}</span></div>
                  </div>

                </div>
                <div className="col-md-12 col-lg-7">
                  <Stripe
                    package={this.state.package}
                    closeModel={() => this.closeModel()}
                  />
                </div>
              </div> </div>
          </ModalBody>
          <ModalFooter className="esignModal-footer">
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Login;

