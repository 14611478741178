var CryptoJS = require("crypto-js");

module.exports = {
    encrpy(result, sectionName) {
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(result), window.TOKEN_SECRET);
        return localStorage.setItem(sectionName, ciphertext);
    },
    decrpt(sectionName) {
        try {
            var data = localStorage.getItem(sectionName);
            if (data !== null) {
                var bytes = CryptoJS.AES.decrypt(data.toString(), window.TOKEN_SECRET);
                var data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }else{
                localStorage.clear();
                // window.location.reload();
                // window.open(`${window.APPURL}#/login`,"_self")
            }
        }
        catch (e) {
            localStorage.clear();
            // window.location.reload();
            // window.open(`${window.APPURL}#/login`,"_self")
        }
    }
}

