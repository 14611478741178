import $ from 'jquery';

export default  () => {
    try {
        $('title').text('eSignPanda: Legally Binding e-Signatures Platform');
        $('meta[name=title]').attr("content", "width=device-width, initial-scale=1, shrink-to-fit=no");
        $('meta[name=keywords]').attr("content", "electronic signature,esign,e signature,sign documents online,electronic signature free,digital signature in word,electronic signature pdf digitally sign pdf,e signature free,digital signature online,sign documents online free,electronic document signing,e signature online,esign documents,electronic signature online");
        $('meta[name=description]').attr("content", "electronic signature platform for business agreements. Sign, send, & manage e-signed documents; create & manage eSign campaigns – all from a single dashboard.");    
    } catch (err) {
        console.log("");
    }
}