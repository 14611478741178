
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/esignpanda.css';
import './styles/styles.css';
import './styles/bootstrap/css/bootstrap.css';
import './styles/flat-icon/flaticon.css';
import './styles/themify-icons/themify-icons.css';
import './styles/package-page-font/flaticon.css';
import './styles/left-panel-icon/flaticon.css';
import App from './App';
import * as serviceWorker from './serviceWorker'; 

window.loginExpiresAfter = 1440;
window.TOKEN_SECRET  = 'pvpnCCZfwOF85pBjbOebZiYIDhZ3w9LZrKwBZ7152K89mPCOHtbRlmr5Z91ci4L';
// window.APPURL = 'https://www.controlfunnel.org.uk/';
// window.APIURL = 'https://www.controlfunnel.org.uk/';

// window.APPURL = 'http://localhost:3000/';
// window.APIURL = 'http://192.168.16.152:3000/';
 
//  window.APPURL = 'http://35.177.9.152:3000/';
//  window.APIURL = 'http://35.177.9.152:3000/';

// window.APPURL = 'http://18.130.29.99:3000/';
// window.APIURL = 'http://18.130.29.99:3000/';

window.APPURL = 'https://www.esignpanda.com/';
window.APIURL = 'https://www.esignpanda.com/';
 

// window.APPURL = 'https://www.esignpanda.com/';
// window.APIURL = 'https://www.esignpanda.com/';


window.eMarkitingUrl = 'https://www.esignrepack.co.uk/';
window.eMarkitingAppURL = 'https://www.esignrepack.co.uk/#/';
// http://192.168.0.141:3000/#/login
// window.eMarkitingUrl = 'http://192.168.0.141:8080/';
// window.eMarkitingAppURL = 'http://192.168.0.141:3000/#/';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
