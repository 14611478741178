import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Body from './Body';
import './libraries/bootstrap/css/bootstrap.min.css'
import './libraries/font-awesome/css/font-awesome.min.css'
import './libraries/animate/animate.min.css'
import './libraries/ionicons/css/ionicons.min.css'
import './libraries/owlcarousel/assets/owl.carousel.min.css'
import './libraries/magnific-popup/magnific-popup.css'
import './img/favicon.png'
import './css/style.css'



class FrontMain extends Component {
  state = {
    pages: []
  }

  onPagesReceived = (pages) => {
    
    this.setState({pages})
  }
  
  render() {
    return (
      <React.Fragment>
        <Header/>
        <div >
          <Body onPagesReceived={this.onPagesReceived}/>
        </div>
        <Footer pages={this.state.pages}/>
      </React.Fragment>
    );
  }
}

export default FrontMain;
