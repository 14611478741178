import $ from 'jquery';

export default  (element, className, type='input', error = null) => {
    try {
        if(element && type==='input')
            $('input[name="' + element + '"]').focus();
        if(element && type!=='input')
            $(`${type}[name="${element}"]`).focus();
        else if (className)
            $(`.${className}`).focus();            
    } catch (err) {
        console.log(err);
    }
}