import React, { Component } from "react";
import {decrpt} from '../comman/Crypto'


import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    StripeProvider,
    Elements,
    injectStripe,
    PostalCodeElement
} from 'react-stripe-elements';
import $ from "jquery";

const createOptions = (padding) => {
    return {
        style: {
            base: {
                // width: "100%",
                // height: "42px",
                // borderRadius: "5px",
                // border: '1px solid #9e9e9e',
                // padding: '10px 10px',
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };
};

class _SplitForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // autoRenew: true,
            accountName: '',
            cardNumber: '',
            cardExpire:'',
            cardCsv:"",
            // zipCode: '',
            // countryName: '',
            errorMessage: '',
            disableSubmit: false
            // countryData: [],
            // isError: false
        }
    }
    
    handleSubmit = () => {
        var th = this;
      
        if(this.state.accountName != ''){
            this.props.stripe.createToken().then((payload) => {
                
                if (payload.error) {
                    this.setState({
                        errorMessage: payload.error.message,
                        isError: true,
                        disableSubmit: false
                    })
                } else {
                    this.setState({disableSubmit: true})
                    var user =  decrpt('LoginSession');
                    var token = localStorage.getItem('accessToken');
                    fetch(window.APIURL + 'api/package/assign', {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }, body: JSON.stringify({
                            "package": th.props.package,
                            'type': th.props.type,
                            "user":  user,
                            "stripe": payload,
                            "cardNumber": th.state.cardNumber,
                            "cardCSV":    th.state.cardCsv  ,
                            "cardExpire" :th.state.cardExpire,
                            isCharge: this.props.isCharge  
                        })
                    }).then(res => res.json()).then(
                        (result) => {
                            this.setState({disableSubmit: false})
                            if (result.status === "success") {
                                localStorage.setItem("check" , true );
                                th.props.closeModel();
                            } else {
                                this.setState({ logout: true });
                            }
                        },
                        (error) => {
                            console.log('');
                        });
                }
            });
        }else{
            this.setState({
                errorMessage: "Name on card is required.",
                isError: true
            })
        }
     
        // ev.preventDefault();
    };
    handleChange = (e) => {
        let val = '';
        if (e && e.value) {
            val = e.value
        }
        this.setState({
            countryName: val
        })
    }
    handleZipCode = (e) => {
        if (e.value <= 6) {
            this.setState({
                zipCode: e.value
            })
        }
    }
    handleCardNumber = (e) => {
         
           if(e.complete == true){
            //    console.log( $("input[name=cardnumber]").value);
            this.setState({
                cardNumber: $("input[name=cardnumber]").value
            })
           }
    }
    handleCardExpire = (e) => {
            this.setState({
                cardExpire: e.value
            })
    }
    handleCardCVC = (e) => {
            this.setState({
                cardCsv: e.value
            })
    }
    
    render() {
    
        return (
            <div className="payment-form">
                <h3 className='modal-title'>Payment Information</h3>
                <form>
                    <div className="row">
                        <div className="form-group col-sm-12 col-xs-12">

                            <input type="text"
                                className="form-control"
                                onChange={(e) => this.setState({ accountName: e.target.value })}
                                name="" placeholder="Name on card" />
                        </div>
                        <div className="form-group col-sm-12 col-xs-12">
                            <CardNumberElement
                            value={this.state.cardNumber}
                                  onChange={(e) => this.handleCardNumber(e)}
                                {...createOptions()} />
                        </div>
                        <div className="form-group col-sm-6 col-xs-12">
                            <CardExpiryElement
                             onChange={(e) => this.handleCardExpire(e)}
                                {...createOptions()}
                            />
                        </div>
                        <div className="form-group col-sm-6 col-xs-12">
                            <CardCVCElement
                             onChange={(e) => this.handleCardCVC(e)}
                               {...createOptions()}
                        />
                     </div>
                      <div className="form-group col-sm-6 col-xs-12">
                            {/* <PostalCodeElement
                                // value={this.state.zipCode}
                                // onChange={(e) => this.handleZipCode(e)}
                                placeholder='Post/Zip Code'
                                {...createOptions()} /> */}
                        </div>
                        <div className="form-group payment-card-country col-sm-6 col-xs-12">
                            {/* <Select options={getCountries()}
                                onChange={(e) => this.handleChange(e)}
                                className="form-control form"
                                style={{ border: "none" }}
                                placeholder="Select country"
                                value={this.state.countryName} /> */}
                        </div>
                    </div>
                </form>
                <p className="text-green secure-line">
                    <i className="fa fa-lock" aria-hidden="true"></i> Your payments are SSL encrypted, <span className="fw600">which means they're 100% safe with us.</span> </p>
                 <p className="text-danger">{ this.state.errorMessage == '' ? this.state.errorMessage : this.state.errorMessage}</p>
                <div className="form-btn text-center">
                    <button type="button"
                        disabled={this.state.disableSubmit}
                        onClick={() => this.handleSubmit()}
                        className="btn btn-green btn-round btn-upgrade btn-checkout"> {this.props.isCharge ? "Checkout" :  "Start Free Trail"}
                     </button>
                </div>
            </div>
        );
    }
}
const SplitForm = injectStripe(_SplitForm);

class Checkout extends React.Component {
    constructor() {
        super();
        this.state = {
            elementFontSize: window.innerWidth < 450 ? '14px' : '18px',
        };
    }

    render() {
        const { elementFontSize } = this.state;
        return (
            <div className="Checkout">

                <Elements>
                    <SplitForm 
                        package = {this.props.package}
                        type = {this.props.type}
                        closeModel ={() => this.props.closeModel()}
                        isCharge={this.props.isCharge}
                    />
                </Elements>

            </div>
        );
    }
}


export const Stripe = (props) => {
    // console.log(props.package);
    return (
        <StripeProvider apiKey='pk_test_60QXFBO1s3yI5N2lAjL4OLvU'>
            <Checkout
              package = {props.package}
              type = {props.type}
              closeModel ={() => props.closeModel()}
              isCharge={props.isCharge}
            />
        </StripeProvider>
    );
};
